import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { AnalyticsService } from '../../../@core/utils';
import { LayoutService } from '../../../@core/utils';
import { filter, map } from 'rxjs/operators';
import { LoginService } from '../../../services/login.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() position = 'normal';
  isDev: string = !environment.production ? '(Development)' : '';
  user = {
    name: '',
    picture: '',
    token: '',
  };

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: UserData,
    private analyticsService: AnalyticsService,
    private layoutService: LayoutService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    this.loginService.getUser((user) => {
      if (user) {
        this.user.name = user.displayName || user.email;
        this.user.picture = user.photoURL;
        this.loginService.user = this.user;

        user.getIdToken(true).then((token) => {
          this.loginService.user.token = token;
          this.loginService.token.next(token);
        });
      }
    });

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'userMenu'),
        map(({ item: { title } }) => title),
      )
      .subscribe((title) => {
        switch (title) {
          case 'Log out':
            this.loginService.logout();
            break;
        }
      });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
}
