import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NbLoginComponent } from '@nebular/auth';
import { LoginService } from '../../services/login.service';
import { GoogleAuthService } from '../../@core/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent extends NbLoginComponent {
  isDev: string = !environment.production ? '(Development)' : '';
  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  rememberMe = false;

  constructor(
    protected router: Router,
    private loginService: LoginService,
    private auth: GoogleAuthService,
  ) {
    super(null, null, null, null);
  }

  login(): void {
    this.errors = [];

    this.loginService.login(this.user)
      .then((res) => {
        this.router.navigate(['/']);
        this.loginService.user = this.user;
      })
      .catch((err) => {
        if (typeof err.message === 'string') {
          this.errors.push(err.message);
        }

      });
  }

  googleLogin() {
    this.auth.googleLogin().then(a => {
      console.log(a);
    });
  }

}
